<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="systemyear">
        <div class="con">
            <div class="tit">
                <p class="p1">系统年份设置</p>
                <p class="p2">默认公历财年：01月——12月</p>
            </div>
            <p class="text_cainian" @click="dianji_zidingyi">添加自定义财年</p>
            <div style="height:1.8rem;background:#EEEEEE;padding:0.27rem 0.3rem;margin-top: 0.33rem;position: relative;">
                <div class="kaishi_tit">
                    <span>开始月份</span>
                    <span>结束月份</span>
                </div>
                <div class="xuanzecainian">
                    <p class="p1">自定义财年：</p>
                    <el-date-picker
                        id='input'
                        v-model="value1"
                        prefix-icon='rqi'
                        clear-icon='qingchu'
                        type="month"
                        format="MM'月'"
                        popper-class='xitong_nianfen'
                        placeholder="开始月份">
                    </el-date-picker>
                    <p :class="value1!=''?'p2':'p3'">{{jieshu_time}}</p>
                    <div style="margin-left:1.4rem;display:flex;" v-if="bianji">
                        <p class="bianji_css" @click="dianji_bianji">编辑</p>
                        <p class="shanchu_css" @click="dianji_shanchu">清空</p>
                    </div>
                </div>
                <div v-if="bianji_sure" class="queding" @click="bianji_cainian">确认</div>
                <div v-if="shanchu_sure" class="queding" @click="shanchu_cainian">确认</div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { query_ent_fiscal_year, set_ent_fiscal_year, delete_ent_fiscal_year } from '../../api/api.js'
export default {
  name: 'systemyear',
  data () {
    return {
      value1: '',
      jieshu_time: '结束月份',
      bianji: false,
      ent_id: '',
      bianji_sure: false,
      shanchu_sure: false,
      jieshu: '',
      jianting: '',
      jichuxinxi: ''
    }
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    }
  },
  watch: {
    value1 () {
      if (this.value1 != '') {
        const d = this.value1
        console.log(d.length)
        let yue = ''
        // if(''+d.length<=2&&d!='01'){
        //     if(Number(d)-1==0){
        //         yue='12'
        //     }else{
        //         yue='0'+(Number(d)-1)
        //     }
        // }else if((''+d).length>2){
        if (d.length != 7) {
          if (Number(this.p(d.getMonth() + 1)) + 11 > 12) {
            yue = Number(this.p(d.getMonth() + 1)) - 1
            if (yue < 10) {
              yue = '0' + yue
            }
          } else {
            yue = '12'
          }
        } else {
          console.log(d.substring(5))
          if (Number(d.substring(5))) {
            if (Number(d.substring(5)) + 11 > 12) {
              yue = Number(d.substring(5)) - 1
              if (yue < 10) {
                yue = '0' + yue
              }
            } else {
              yue = '12'
            } 
          }
        }
        console.log(yue)
        this.bianji_sure = true
        // }else if(d=='01'){
        //     yue='12'
        // }
        this.jieshu_time = yue + '月'
        this.jieshu = yue
        this.bianji = true
      } else {
        this.jieshu_time = '结束日期'
        this.bianji = false
      }
    }
  },
  mounted () {
    this.jichu()
  },
  methods: {
    // 基础信息
    jichu () {
      query_ent_fiscal_year({
        data: {
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10097) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != null) {
            this.value1 = '2019-' + this.$func.pa(Number(date.month_start))
          }
        } else if (res.data.code == 10098) {
        }
      })
    },
    p (s) {
      return s < 10 ? '0' + s : s
    },
    dianji_zidingyi () {
      if (this.value1 == '') {
        const input = document.getElementById('input')
        input.focus()
      } else {
        this.$message({
          message: '已有日期',
          type: 'warning'
        }) 
      }
    },
    // 点击编辑
    dianji_bianji () {
      const input = document.getElementById('input')
      input.focus()
    },
    // 点击删除
    dianji_shanchu () {
      this.value1 = ''
      this.jieshu_time = ''
      this.shanchu_sure = true
    },
    bianji_cainian () {
      console.log(this.jieshu)
      if (this.value1.length != 0) {
        const yue = this.value1.getMonth() + 1 + ''
        const yueed = Number(this.jieshu) + ''
        set_ent_fiscal_year({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            month_start: yue,
            month_end: yueed
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10095) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.jichu()
            this.bianji_sure = false
          } else if (res.data.code == 10096) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          }
        })
      } else {
        delete_ent_fiscal_year({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10172) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.jichu()
            this.shanchu_sure = false
          } else if (res.data.code == 10173) {
            this.$message({
              message: '删除失败',
              type: 'error'
            })
          }
        })
      }
    },
    shanchu_cainian () {
      if (this.value1.length != 0) {
        const yue = this.value1.getMonth() + 1 + ''
        const yueed = Number(this.jieshu) + ''
        set_ent_fiscal_year({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            month_start: yue,
            month_end: yueed
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10095) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.jichu()
            this.bianji_sure = false
          } else if (res.data.code == 10096) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          }
        })
      } else {
        delete_ent_fiscal_year({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10172) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.jichu()
            this.shanchu_sure = false
          } else if (res.data.code == 10173) {
            this.$message({
              message: '删除失败',
              type: 'error'
            })
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.systemyear {
    height:100%;
    width:100%;
    .con{
        padding: 0.33rem;
        .tit{
            display: flex;
            align-items: center;
            .p1{
                font-size: 0.18rem;
                color:#1A2533;
            }
            .p2{
                margin-left: 0.24rem;
                font-size: 0.14rem;
                color:#888888;
            }
            margin-bottom: 0.63rem;
        }
        .text_cainian{
            font-size: 0.12rem;
            display: inline-block;
            // width: 1rem;
            padding: 0.05rem 0.1rem;
            // height:0.22rem;
            // line-height: 0.2rem;
            // text-align: center;
            color: #FFFFFF;
            // padding: 0.05rem 0.18rem;
            background: #8d52e3;
            border-radius: 0.02rem;
            cursor:pointer;
        }
        .kaishi_tit{
            padding-left: 2rem;
            height: 0.2rem;
            display: flex;
            align-items: center;
            span{
                display: inline-block;
                margin-right: 0.98rem;
                color:#1A2533;
                font-size: 0.18rem;
                font-weight: 600;
            }
        }
        .xuanzecainian{
            display: flex;
            align-items: center;
            margin-top: 0.1rem;
            padding-bottom:0.05rem; 
            border-bottom:0.01rem solid #eee;
            .p1{
                font-size: 0.16rem;
                color: #1A2533;
            }
            .p2{
                z-index: 10;
                margin-left: 0.3rem;
                font-size: 0.14rem;
                color:#606266;
            }
            .p3{
                z-index: 10;
                margin-left: 0.6rem;
                font-size: 0.14rem;
                color:rgb(198, 198, 201);
            }
            .bianji_css,.shanchu_css{
                color:#8d52e3;
                font-size: 0.16rem;
                cursor:pointer;
                margin-left: 0.58rem;
            }
        }
        .queding{
            font-size: 0.18rem;
            color:#fff;
            display: inline-block;
            background: #8d52e3;
            padding: 0.1rem 0.23rem;
            border-radius: 0.03rem;
            position: absolute;
            right:0.29rem;
            bottom: 0.22rem;
        }
    }
}
</style>
